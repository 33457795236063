import Vue from 'vue'
import axios from 'axios'
import store from '@/store/index'
import router from '@/router/index'

axios.defaults.baseURL = process.env.VUE_APP_API_URL

const token = localStorage.getItem('token')

if (token) {
  axios.defaults.headers.common.Authorization = 'Bearer ' + token
}

axios.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config

    // if(!error.response) {
    //     Vue.toasted.error('Keine Internet Verbindung!');
    // }

    switch (error.response.status) {
      case 400:
        Vue.toasted.error(error.response.data.detail)
        break
      case 401:
        // if (error.response.data.message === 'Bad credentials') {
        //
        // }
        if (error.response.data.message === 'Expired JWT Token') {
          const response = await store.dispatch('auth/refreshToken')
          originalRequest.headers.Authorization = 'Bearer ' + response.data.token
          return axios(originalRequest)
        }
        if (error.response.data.message === 'Invalid JWT Token') {
          Vue.toasted.error(error.response.data.message)
          store.dispatch('auth/logout')
          router.push({ name: 'login' })
        }
        break
      case 403:
        Vue.toasted.error(error.response.data.detail)
        break
      case 404:
        Vue.toasted.error(error.response.data.detail)
        break
      case 422:
        Vue.toasted.error(error.response.data['hydra:description'])
        break
      case 500:
        Vue.toasted.error(error.response.data.detail)
        break
    }
    return Promise.reject(error)
  })

export default axios
