<template>

    <form v-on:submit.prevent="">

        <div class="middle">

            <div class="group">
                <div class="title">Name</div>

                <BaseInputFloatinglabel v-model="form.name"
                                        icon="grin-stars"
                                        placeholder="Künstlername"
                                        type="text"/>

                <BaseInputFloatinglabel v-model="form.fullName"
                                        icon="user"
                                        placeholder="Vollständiger Name"
                                        type="text"/>

                <div class="title">Kategorie & Zielgruppe</div>

                <multiselect v-model="form.influencerCategory"
                             :options="influencerCategories"
                             :show-labels="false"
                             label="name"
                             placeholder="Kategorie">
                    <template #placeholder>
                        <font-awesome-icon icon="tags"/>
                        <span>Kategorie</span>
                    </template>
                    <template #singleLabel="props">
                        <font-awesome-icon icon="tags"/>
                        <span>{{ props.option.name }}</span>
                    </template>
                </multiselect>

                <multiselect v-model="form.influencerSubCategories"
                             :multiple="true"
                             :options="influencerSubCategories"
                             :show-labels="false"
                             label="name"
                             placeholder="Schwerpunkte"
                             track-by="id">
                    <template #placeholder>
                        <font-awesome-icon icon="th"/>
                        <span>Schwerpunkte</span>
                    </template>
                </multiselect>

                <multiselect v-model="form.influencerAudiences"
                             :multiple="true"
                             :options="influencerAudiences"
                             :show-labels="false"
                             label="name"
                             placeholder="Zielgruppen"
                             track-by="id">
                    <template #placeholder>
                        <font-awesome-icon icon="bullseye"/>
                        <span>Zielgruppen</span>
                    </template>
                </multiselect>

                <BaseInputFloatinglabel v-model="form.region"
                                        icon="map-marker-alt"
                                        placeholder="Region"
                                        type="text"/>

                <BaseTextarea v-model="form.notes" placeholder="Notizen" icon="sticky-note"/>

                <BaseCheckbox v-model="form.interviews"
                              placeholder="Interviews"
                              :id="form.id"
                              field="interviews"/>
                <BaseCheckbox v-model="form.contests"
                              placeholder="Gewinnspiele"
                              :id="form.id"
                              field="contests"/>
            </div>

            <div class="group">
                <div class="title">Ansprechpartner</div>

                <multiselect v-model="form.influencerNetwork"
                             :options="influencerNetworks"
                             :show-labels="false"
                             label="name"
                             placeholder="Netzwerk">
                    <template #placeholder>
                        <font-awesome-icon icon="project-diagram"/>
                        <span>Netzwerk</span>
                    </template>
                    <template #singleLabel="props">
                        <font-awesome-icon icon="project-diagram"/>
                        <span>{{ props.option.name }}</span>
                    </template>
                </multiselect>

                <BaseInputFloatinglabel v-model="form.asp"
                                        icon="user-tie"
                                        placeholder="Name"
                                        type="text"/>
                <BaseInputFloatinglabel v-model="form.email"
                                        icon="envelope"
                                        placeholder="E-Mail"
                                        type="email"/>
                <BaseInputFloatinglabel v-model="form.phone"
                                        icon="phone-alt"
                                        placeholder="Telefon"
                                        type="tel"/>
                <BaseInputFloatinglabel v-model="form.street"
                                        icon="map-marker-alt"
                                        placeholder="Straße"
                                        type="text"/>
                <BaseInputFloatinglabel v-model="form.zip"
                                        icon="map-marker-alt"
                                        placeholder="PLZ"
                                        type="number"/>
                <BaseInputFloatinglabel v-model="form.city"
                                        icon="city"
                                        placeholder="Ort"
                                        type="text"/>
                <BaseInputFloatinglabel v-model="form.country"
                                        icon="flag"
                                        placeholder="Land"
                                        type="text"/>
            </div>

            <div class="group">

                <div class="title">Kanäle</div>
                <BaseInputFloatinglabel v-model="form.facebookPageName"
                                        :icon="['fab', 'facebook']"
                                        placeholder="Facebook"
                                        type="text"/>
                <BaseInputFloatinglabel v-model="form.instagramProfileName"
                                        :icon="['fab', 'instagram']"
                                        placeholder="Instagram"
                                        type="text"/>
                <BaseInputFloatinglabel v-model="form.youtubeChannelId"
                                        :icon="['fab', 'youtube']"
                                        placeholder="YouTube"
                                        type="text"/>
                <BaseInputFloatinglabel v-model="form.twitterProfileName"
                                        :icon="['fab', 'x-twitter']"
                                        placeholder="X"
                                        type="text"/>
                <BaseInputFloatinglabel v-model="form.tiktokUserId"
                                        :icon="['fab', 'tiktok']"
                                        placeholder="TikTok"
                                        type="text"/>
                <BaseInputFloatinglabel v-model="form.twitchChannelId"
                                        :icon="['fab', 'twitch']"
                                        placeholder="Twitch"
                                        type="text"/>
                <BaseInputFloatinglabel v-model="form.applePodcastId"
                                        icon="podcast"
                                        placeholder="Apple Podcast"
                                        type="text"/>
                <BaseInputFloatinglabel v-model="form.blog"
                                        icon="desktop"
                                        placeholder="Website"
                                        type="text"/>

            </div>

        </div>

        <div class="action">
          <BaseButton v-if="type === 'create'"
                       :loading="creating"
                       :disabled="checkForm || creating"
                       icon="plus"
                       label="Erstellen"
                       :submit="true"
                       @click="create"/>
          <BaseButton v-if="hasManagerRole && type === 'edit'"
                       :loading="removing"
                       :disabled="removing"
                       icon="trash"
                       label="Löschen"
                       :remove="true"
                       @click="remove"/>
          <BaseButton v-if="type === 'edit'"
                       :loading="updating"
                       :disabled="checkForm || updating"
                       icon="save"
                       label="Speichern"
                       :submit="true"
                       @click="update"/>
        </div>

    </form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import BaseTextarea from '@/components/base/BaseTextarea'
import { cloneDeep } from 'lodash'

export default {
  name: 'InfluencerForm',
  components: { BaseTextarea },
  props: {
    influencer: Object,
    type: String
  },
  data () {
    return {
      form: {},
      creating: false,
      updating: false,
      removing: false
    }
  },
  created () {
    this.fetchData()
    this.form = cloneDeep(this.influencer)
  },
  methods: {
    async fetchData () {
      await Promise.all([
        this.influencerAudiences.length || this.getInfluencerAudiences(),
        this.influencerCategories.length || this.getInfluencerCategories(),
        this.influencerSubCategories.length || this.getInfluencerSubCategories(),
        this.influencerNetworks.length || this.getInfluencerNetworks()
      ])
    },
    async create () {
      try {
        this.creating = true
        this.form.updatedBy = 'users/' + this.authUser.id
        this.form.updatedAt = moment().format()
        const response = await this.createInfluencer({ data: this.form })
        this.$vfm.hide('influencer-create')
        this.$toasted.success('Influencer ' + response.data.name + ' erstellt', { icon: 'fas fa-grin-stars' })
        this.creating = false
        await this.getInfluencers()
      } catch (error) {
        this.creating = false
      }
    },
    async update () {
      try {
        this.updating = true
        this.form.updatedBy = 'users/' + this.authUser.id
        this.form.updatedAt = moment().format()
        const response = await this.updateInfluencer({ id: this.form.id, data: this.form })
        this.$vfm.hide('influencer-edit')
        this.$toasted.success('Influencer ' + response.data.name + ' gespeichert', { icon: 'fas fa-grin-stars' })
        this.updating = false
        await this.getInfluencer({ id: this.form.id })
      } catch (error) {
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deleteInfluencer({ id: this.form.id })
        this.$vfm.hide('influencer-edit')
        this.$toasted.success('Influencer gelöscht', { icon: 'fas fa-grin-stars' })
        this.removing = false
        await this.getInfluencers()
      } catch (error) {
        this.removing = false
      }
    },
    ...mapActions({
      createInfluencer: 'influencers/create',
      updateInfluencer: 'influencers/replace',
      deleteInfluencer: 'influencers/destroy',
      getInfluencer: 'influencers/fetchSingle',
      getInfluencers: 'influencers/fetchList',
      getInfluencerCategories: 'influencerCategories/fetchList',
      getInfluencerSubCategories: 'influencerSubCategories/fetchList',
      getInfluencerAudiences: 'influencerAudiences/fetchList',
      getInfluencerNetworks: 'influencerNetworks/fetchList'
    })
  },
  computed: {
    checkForm () {
      return !(this.form.name && this.validEmail)
    },
    validEmail () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.form.email)
    },
    ...mapGetters({
      influencerCategories: 'influencerCategories/list',
      influencerSubCategories: 'influencerSubCategories/list',
      influencerAudiences: 'influencerAudiences/list',
      influencerNetworks: 'influencerNetworks/list',
      hasManagerRole: 'auth/hasManagerRole',
      hasAdminRole: 'auth/hasAdminRole',
      authUser: 'auth/authUser'
    })
  }
}
</script>
